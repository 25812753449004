import { useQuery } from "@tanstack/react-query";
import { Suspense, lazy, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileStatus } from "../../../api/authApi";
import LoadingSpinner from "../../../components/Loading Spinner/LoadingSpinner";
import { fetchUserProfile } from "../../../redux/actions/userInfoActions";
import {
  getIsProfileAvailable,
  getUserInfoStatus,
  updateIsProfileAvailable,
} from "../../../redux/slices/userInfoSlice";
const TeacherEnterInfo = lazy(() =>
  import("../Submit Details/TeacherEnterInfo")
);
const MainDashLayout = lazy(() =>
  import("../../../components/DashBoard Main Layout/MainDashLayout")
);

const TeacherLayout = () => {
  const userIsProfileAvailable = useSelector(getIsProfileAvailable);
  const userInfoStatus = useSelector(getUserInfoStatus);
  const dispatch = useDispatch();

  // state to control whether provile details is available or not
  const [isProfileAvailable, setIsProfileAvailable] = useState(
    userIsProfileAvailable || null
  );

  // making api call to check if user details is available in server
  const checkProfileStatus = useQuery({
    queryKey: ["teacher/profileStatus"],
    queryFn: getProfileStatus,
    retry: 2,
    enabled: isProfileAvailable === null,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setIsProfileAvailable(response.data.available);
      dispatch(updateIsProfileAvailable(response.data.available));
    },
    useErrorBoundary: (error) => {
      console.log(error);
      if (error.response.status === 500) {
        console.log(error.response);
      }
    },
  });

  const teacherProfile = useQuery({
    queryKey: ["teacherProfileInfo"],
    queryFn: () => dispatch(fetchUserProfile()),

    enabled: userInfoStatus === "idle" && userIsProfileAvailable === true,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  // sidebar links
  const navigationLinks = [
    {
      path: "profile",
      icon: <i className="fas fa-user"></i>,
      linkName: "My Profile",
    },
    {
      path: "edit-profile",
      icon: <i className="fas fa-user-edit"></i>,
      linkName: "Edit Profile",
    },
    {
      path: "student-requests",
      icon: <i className="fas fa-user-plus"></i>,
      linkName: "Student Requests",
    },
    {
      path: "activate-enrollment",
      icon: <i className="fas fa-user-check"></i>,
      linkName: "Activate Enrollments",
    },
    {
      path: "enrolled-students",
      icon: <i className="fas fa-user-graduate"></i>,
      linkName: "Enrolled Students",
    },
    {
      path: "fee-management",
      icon: <i className="fas fa-wallet"></i>,
      linkName: "Fee Management",
    },
  ];

  if (isProfileAvailable === null) {
    return <LoadingSpinner />;
  }
  if (!isProfileAvailable) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <TeacherEnterInfo setIsProfileAvailable={setIsProfileAvailable} />
      </Suspense>
    );
  }

  if (isProfileAvailable) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <MainDashLayout sidebarLinks={navigationLinks} />
      </Suspense>
    );
  }
};

export default TeacherLayout;
