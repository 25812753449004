import {
  PersonalInformation,
  PhotoContainer,
  ProfilePhoto,
  VerifiefteacherInfoContainer,
} from "./personalStyles";
import { getResidingState } from "../../../utils/getResdingState";
import dayjs from "dayjs";

const PersonalInfo = ({ profile }) => {
  return (
    <>
      <PhotoContainer>
        <ProfilePhoto>
          <img src={profile.profile_photo} alt="prfile photo" />
        </ProfilePhoto>
        {profile?.profile_photo && (
          <VerifiefteacherInfoContainer $verified={profile.profile_status}>
            <p>
              {profile.profile_status === "PENDING" ? (
                <i className="fas fa-times-circle"></i>
              ) : (
                <i className="fas fa-check-circle"></i>
              )}
              &nbsp;
              {profile.profile_status}
            </p>
          </VerifiefteacherInfoContainer>
        )}
      </PhotoContainer>
      <PersonalInformation>
        <p>
          <span>Name : </span>
          {profile && profile.name}
        </p>
        <p>
          <span>Email : </span>
          {profile && profile.email}
        </p>
        <p>
          <span>Adhar Card Number : </span>
          {profile && profile.adhaar_number}
        </p>
        <p>
          <span>Phone : </span>
          {profile && profile.phone}
        </p>

        <p>
          <span>State : </span>
          {profile && getResidingState(profile.state)}
        </p>
        <p>
          <span>Full Address : </span>
          {profile && profile.full_address}
        </p>
        <p>
          <span>Date of Birth : </span>
          {profile && dayjs(profile.date_of_birth).format("MMMM D, YYYY")}
        </p>
        <p>
          <span>Gender : </span>
          {profile && profile.gender}
        </p>
      </PersonalInformation>
    </>
  );
};

export default PersonalInfo;
