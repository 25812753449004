import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { NotificationBar } from "../../../components/notificationBar";
import { logoutAction } from "../../../redux/actions/authAction";
import { staticUrl } from "../../../utils/axiosInstance";
import EnterDetailsForm from "./Forms/EnterDetailsForm";
import {
  FullLogo,
  Header,
  LeftContainer,
  LogoutButton,
  RightContainer,
  SmallLogo,
} from "./SumitDetailsStyles";

import { createStudentProfile } from "../../../redux/actions/userInfoActions";
import { setProgress } from "../../../redux/slices/loadingbarSlice";
import { setMetaData } from "../../../redux/slices/metaDataSlice";
import { resizeProfileImage } from "../../../utils/imageModification";

const SubmitDetails = ({ setIsProfileAvailable }) => {
  // initialising navigate
  const navigate = useNavigate();

  // initialising dispatch

  const dispatch = useDispatch();

  //state to get the image
  const [photo, setPhoto] = useState("");

  const [photoPreview, setPhotoPreview] = useState(
    "https://schoolies-spaces.sgp1.digitaloceanspaces.com/static/images/blank-profile-picture.png"
  );

  // state to control ui while resing the image
  const [isPhotoResizing, setIsPhotoResizing] = useState(false);

  //initial states of form values
  const [detailsData, setDetailsData] = useState({
    name: "",
    dob: "",
    gender: "",
    parentName: "",
    studentPhone: "",
    parentPhone: "",
    state: "",
    address: "",
    standard: "",
    schoolName: "",
    board: "",
  });

  // function to change the state of photo
  const changePhoto = (e) => {
    const file = e.target.files[0];

    if (file.size > 10485760) {
      NotificationBar(
        `The image size is greater than 10mb. Please upload an image of a smaller size.`,
        "info"
      );
      return;
    }
    const targetFileSize = 200 * 1024; // Maximum file size in bytes (200 KB)

    if (file.size > targetFileSize) {
      setIsPhotoResizing(true);
      setPhotoPreview(URL.createObjectURL(file));
      // Check if the file size is greater than the maximum size
      resizeProfileImage(file, (resizedFile) => {
        setPhoto(resizedFile);
        setIsPhotoResizing(false);
      });
    } else {
      setPhoto(file);
      setPhotoPreview(URL.createObjectURL(file));
    }
  };

  // onchange function for form
  const handleChangeFormData = (e) => {
    setDetailsData({
      ...detailsData,
      [e.target.name]: e.target.value,
    });
  };
  // onchange handler for entering only numbers
  const handleOnlyNumbers = (e) => {
    const regex = /^[0-9\b]+$/;

    const value = e.target.value;
    if (value === "" || regex.test(value)) {
      setDetailsData({
        ...detailsData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // function to submit data
  const handleSubmitDetailsData = async (e) => {
    dispatch(setProgress(20));
    e.preventDefault();

    try {
      dispatch(setProgress(40));

      let response = await dispatch(
        createStudentProfile({ detailsData, photo })
      ).unwrap();
      NotificationBar(`${response.message}`, "success", 4000);

      setIsProfileAvailable(true);
      return navigate(`profile`);
    } catch (error) {
      if (error.response) {
        NotificationBar(
          "We are facing some problems. Please try submitting your details after some time.",
          "error",
          4000
        );
      } else {
        NotificationBar(
          "We are facing server problems. Please try submitting your details after some time.",
          "error",
          4000
        );
      }
    } finally {
      dispatch(setProgress(100));
    }
  };

  useEffect(() => {
    dispatch(setMetaData({ title: "Submit Detail" }));
  }, []);

  // function to handle logout
  const handleLogout = async () => {
    try {
      let response = await dispatch(logoutAction()).unrwap();
      return navigate("/");
    } catch (error) {}
  };

  return (
    <>
      <Header>
        <LeftContainer>
          <FullLogo
            src={`${staticUrl}images/logo_name_header.svg`}
            alt="schoolies"
          />
          <SmallLogo src={`${staticUrl}images/only-logo.svg`} alt="schoolies" />
        </LeftContainer>
        <RightContainer>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </RightContainer>
      </Header>

      <EnterDetailsForm
        detailsData={detailsData}
        changePhoto={changePhoto}
        isPhotoResizing={isPhotoResizing}
        handleSubmitDetailsData={handleSubmitDetailsData}
        handleChangeFormData={handleChangeFormData}
        handleOnlyNumbers={handleOnlyNumbers}
        photo={photo}
        photoPreview={photoPreview}
      />
    </>
  );
};

export default SubmitDetails;
