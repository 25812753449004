import { useQuery } from "@tanstack/react-query";
import { useState, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileStatus } from "../../../api/authApi";
import LoadingSpinner from "../../../components/Loading Spinner/LoadingSpinner";
import { fetchUserProfile } from "../../../redux/actions/userInfoActions";
import {
  getIsProfileAvailable,
  getUserInfoStatus,
  updateIsProfileAvailable,
} from "../../../redux/slices/userInfoSlice";
const SubmitDetails = lazy(() => import("../Submit Details/SubmitDetails"));
const MainDashLayout = lazy(() =>
  import("../../../components/DashBoard Main Layout/MainDashLayout")
);

const StudentLayout = () => {
  const userInfoStatus = useSelector(getUserInfoStatus);
  const userIsProfileAvailable = useSelector(getIsProfileAvailable);

  const [isProfileAvailable, setIsProfileAvailable] = useState(
    userIsProfileAvailable || null
  );

  //initialising dispatch
  const dispatch = useDispatch();

  const checkProfileStatus = useQuery({
    queryKey: ["profileStatus"],
    queryFn: getProfileStatus,
    retry: 1,
    enabled: isProfileAvailable === null,
    onSuccess: (response) => {
      setIsProfileAvailable(response.data.available);
      dispatch(updateIsProfileAvailable(response.data.available));
    },
    useErrorBoundary: (error) => {
      isProfileAvailable(null);
      if (error.response.status === 500) {
        console.log(error.response);
      }
    },
  });

  const studentProfile = useQuery({
    queryKey: ["student/studentProfile"],
    queryFn: () => dispatch(fetchUserProfile()),
    enabled: userInfoStatus === "idle" && userIsProfileAvailable === true,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const navigationLinks = [
    {
      path: "profile",
      icon: <i className="fas fa-user"></i>,
      linkName: "Profile",
    },
    {
      path: "edit-profile",
      icon: <i className="fas fa-user-edit"></i>,
      linkName: "Edit Profile",
    },
    {
      path: "request-status",
      icon: <i className="fas fa-search"></i>,
      linkName: "Request Status",
    },
    {
      path: "myteacher",
      icon: <i className="fas fa-chalkboard-teacher"></i>,
      linkName: "My Teachers",
    },
    {
      path: "fee-management",
      icon: <i className="fas fa-wallet"></i>,
      linkName: "Fee Management",
    },
  ];

  if (isProfileAvailable === null) {
    return <LoadingSpinner />;
  }
  if (!isProfileAvailable) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <SubmitDetails setIsProfileAvailable={setIsProfileAvailable} />
      </Suspense>
    );
  }

  if (isProfileAvailable) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <MainDashLayout sidebarLinks={navigationLinks} />
      </Suspense>
    );
  }
};

export default StudentLayout;
