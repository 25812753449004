import styled, { css } from "styled-components";

const PhotoContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 35em) {
    padding: 0.8rem;
  }
`;

const ProfilePhoto = styled.div`
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
  height: 20rem;
  width: 20rem;

  @media screen and (max-width: 35em) {
    height: 13rem;
    width: 13rem;
  }
  @media screen and (min-width: 35em) and (max-width: 45em) {
    height: 18rem;
    width: 18rem;
  }
`;

const VerifiefteacherInfoContainer = styled.div`
  /* border: 1px solid green; */
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8rem;

  background: ${({ $verified }) =>
    $verified === "PENDING" ? "rgb(216, 0, 0)" : "#01fb1a"};
  p {
    font-size: 1rem;
    color: #fff;
    text-align: center;
  }
`;

const PersonalInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  word-break: break-all;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 0.8rem 1rem;
  color: var(--clr-neutral-900);
  font-size: 1rem;
  span {
    font-weight: var(--fw-bold);
  }

  @media screen and (max-width: 50em) {
    padding: 0.5rem;
    /* font-size: 0.9rem; */
  }
`;

export {
  PhotoContainer,
  ProfilePhoto,
  VerifiefteacherInfoContainer,
  PersonalInformation,
};
