import { useQuery } from "@tanstack/react-query";
import { useEffect, useState, Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { getLocation } from "../../../api/locationApi";
import { NotificationBar } from "../../../components/notificationBar";
import useDebounce from "../../../hooks/useDebounce";
import { updateTeacherProfile } from "../../../redux/actions/userInfoActions";
import {
  getBoardOptions,
  getSubjectOptions,
} from "../../../redux/slices/optionsSlice";
import { getUserProfile } from "../../../redux/slices/userInfoSlice";
import { getResidingState } from "../../../utils/getResdingState";
import { resizeProfileImage } from "../../../utils/imageModification";
import {
  BtnContainer,
  Container,
  DetailsForm,
  FormBody,
  Header,
  NextBtn,
  PrevBtn,
  SubmitBtn,
} from "./editProStyles";
import PersonalDetailsForm from "./personal detail form/PersonalDetailsForm";
const FeeIndex = lazy(() => import("./multi fee/FeeIndex"));

const TuitionForm = lazy(() => import("./tuition form details/TuitionForm"));
const EditDetailsForm = ({}) => {
  // getting information from slices
  const profile = useSelector(getUserProfile);

  const subjectOptions = useSelector(getSubjectOptions);
  const boardOptions = useSelector(getBoardOptions);
  // initialising navigation
  const navigate = useNavigate();

  // initialising dispatch
  const dispatch = useDispatch();

  // state to for form
  const [detailsData, setDetailsData] = useState({
    name: "",
    adhaar_number: "",
    phone: "",
    location: "",
    state: "",
    full_address: "",
    date_of_birth: "",
    gender: "",
    current_profession: "",
    name_of_school: "",
    subject: "",
    shift: "",
    board: "",
    tuition_type: "",
    general_tuition_classes: "",
    home_tuition_classes: "",
  });

  // state to store the fee data
  const [feeData, setFeeData] = useState([
    {
      tuitionTypeForFee: "0",
      classForFee: "",
      feeAmount: "",
    },
  ]);

  //state to get the image
  const [photo, setPhoto] = useState("");

  const [photoPreview, setPhotoPreview] = useState("");

  // state to control ui while resing the image
  const [isPhotoResizing, setIsPhotoResizing] = useState(false);

  //state to control loading
  const [progress, setProgress] = useState(0);

  // states for subject choices
  const [subChoices, setSubChoices] = useState([]);
  const [selectedSubChoices, setSelectedSubChoices] = useState([]);

  // states for board choices
  const [boardChoices, setBoardChoices] = useState([]);
  const [selectedBoardChoices, setSelectedBoardChoices] = useState([]);

  // state for selected shifts
  const [selectedShift, setSelectedShift] = useState([]);

  // state to store the selected values for class in fee section
  const [selectedClass, setSelectedClass] = useState([[]]);

  /*
  state to store the classes choosen in tuition page
  to give them as option in fee page
  */
  const [selectedGeneralClasses, setSelectedGeneralClasses] = useState([]);
  const [selectedHomeClasses, setSelectedHomeClasses] = useState([]);

  const tuitionClassesStates = {
    selectedGeneralClasses,
    setSelectedGeneralClasses,
    setSelectedHomeClasses,
    selectedHomeClasses,
  };

  // state to store the ids of removed fee data
  const [rmvFeeDataIds, setRmvFeeDataIds] = useState([]);

  const [page, setPage] = useState(0);

  const heading = ["Personal Details", "Tuition Details", "Fee Details"];

  const [locationQuery, setLocationQuery] = useState("");

  const [isLocationSelected, setIsLocationSelected] = useState(true);

  const debouncedQuery = useDebounce(detailsData.location, 350);

  const searchLocation = useQuery({
    queryKey: ["teacher/edit/searchLocation", locationQuery],
    queryFn: () => getLocation(locationQuery),
    retry: false,
    enabled: locationQuery !== "" && detailsData.location !== "",
    refetchOnWindowFocus: false,
  });

  // this is used to assign prefils to the form states
  useEffect(() => {
    // assigning the details data
    setDetailsData({
      ...detailsData,
      name: profile?.name || "",
      adhaar_number: profile?.adhaar_number || "",
      phone: profile?.phone || "",
      current_profession: profile?.current_profession || "",
      location: profile?.location || "",
      full_address: profile?.full_address || "",
      state: profile?.state || "",
      date_of_birth: profile?.date_of_birth || "",
      name_of_school: profile?.name_of_school || "",
      subject: profile?.subject || "",
      shift: profile?.shift || "",
      board: profile?.board || "",
      gender: profile?.gender || "",
      general_tuition_classes: profile?.general_tuition_classes || "",
      home_tuition_classes: profile?.home_tuition_classes || "",
      tuition_type: profile?.tuition_type || "",
    });

    // assigning the photo and photo preview
    setPhotoPreview(profile?.profile_photo || "");
    setPhoto(profile?.profile_photo || "");

    // assigning the pre selects for shift
    if (profile.shift) {
      let alreadySelectedShifts = profile.shift.split(",");
      setSelectedShift(
        alreadySelectedShifts.map((element) => ({
          value: element,
          label: element,
        }))
      );
    }

    // assigning pre select for subject
    if (profile.subject) {
      let alreadySelectedSubject = profile.subject.split(",");
      setSelectedSubChoices(
        alreadySelectedSubject.map((element) => ({
          value: element,
          label: element,
        }))
      );
    }

    // assigning pre select for board
    if (profile.board) {
      let alreadySelectedBoard = profile.board.split(",");
      setSelectedBoardChoices(
        alreadySelectedBoard.map((element) => ({
          value: element,
          label: element,
        }))
      );
    }

    // assigning fee data
    if (profile.tch_fee && profile.tch_fee.length > 0) {
      let feeArr = profile.tch_fee;
      setFeeData(
        feeArr.map((element) => ({
          id: element.id,
          tuitionTypeForFee: element.tuition_type,
          classForFee: element.for_classes,
          feeAmount: element.tch_fee_amount,
        }))
      );

      let selectedFeeClasses = feeArr.map((element) => {
        let classOptions = element.for_classes.split(",").map((cls) => ({
          value: cls,
          label: cls,
        }));
        return classOptions;
      });
      setSelectedClass(selectedFeeClasses);
    }
    if (profile.general_tuition_classes) {
      let preSelGeneralTuitionClasses =
        profile.general_tuition_classes.split(",");
      setSelectedGeneralClasses(
        preSelGeneralTuitionClasses.map((element) => ({
          value: element,
          label: element,
        }))
      );
    }

    if (profile.home_tuition_classes) {
      let preSelHomeTuitionClasses = profile.home_tuition_classes.split(",");
      setSelectedHomeClasses(
        preSelHomeTuitionClasses.map((element) => ({
          value: element,
          label: element,
        }))
      );
    }

    if (subjectOptions.length > 0) {
      setSubChoices(
        subjectOptions.map((subject) => ({
          value: subject.subject_name,
          label: subject.subject_name,
        }))
      );
    }

    if (boardOptions.length > 0) {
      setBoardChoices(
        boardOptions.map((board) => ({
          value: board.board_name,
          label: board.board_name,
        }))
      );
    }
  }, [profile]);

  // function to change the state of photo
  const changePhoto = (e) => {
    const file = e.target.files[0];

    if (file.size > 10485760) {
      NotificationBar(
        `The image size is greater than 10mb. Please upload an image of a smaller size.`,
        "info"
      );
      return;
    }
    const targetFileSize = 200 * 1024; // Maximum file size in bytes (200 KB)

    setPhotoPreview(URL.createObjectURL(file));
    if (file.size > targetFileSize) {
      setIsPhotoResizing(true);
      // Check if the file size is greater than the maximum size
      resizeProfileImage(file, (resizedFile) => {
        setPhoto(resizedFile);
        setIsPhotoResizing(false);
      });
    } else {
      setPhoto(file);
      setPhotoPreview(URL.createObjectURL(file));
    }
  };

  // on change function to change the values of the form
  const handleChangeFormData = (e) => {
    // this is how we do when to specifically define a type in the target
    const { name, value } = e.target;
    setDetailsData({
      ...detailsData,
      [name]: value,
    });

    if (name === "tuition_type") {
      setFeeData([
        {
          tuitionTypeForFee: "0",
          classForFee: "",
          feeAmount: "",
        },
      ]);
      setSelectedClass([[]]);
      if (!rmvFeeDataIds.length > 0) {
        setRmvFeeDataIds(feeData.map((element) => element.id));
      }
      NotificationBar(
        `Since you have changed your Tuition Type, your Fee Details have been removed. Please re-fill your Fee Details in accordance with the Tuition Type choosen`,
        `info`,
        7000
      );
    }
  };

  const handleLocationOnChange = (e) => {
    // THIS IS HANDLE LOCATION INPUT ON CHANGE

    const { value } = e.target;

    if (detailsData.state === "0") {
      NotificationBar("Please select a state first", "error");
      return;
    }

    if (value === "") {
      setIsLocationSelected(true);
    } else {
      setIsLocationSelected(false);
    }
    setDetailsData({
      ...detailsData,
      location: value,
    });
  };

  const handleLocationSelectClick = (locationSuggestion) => {
    setDetailsData({
      ...detailsData,
      location: locationSuggestion,
    });

    setIsLocationSelected(true);
  };

  useEffect(() => {
    // THIS DEBOUNCES THE LOCATION QUERY
    if (debouncedQuery && !isLocationSelected) {
      let query = `${debouncedQuery} ${getResidingState(detailsData.state)}`;
      setLocationQuery(query);
    }
  }, [debouncedQuery, isLocationSelected]);

  // onchange handler for entering only numbers
  const handleOnlyNumbers = (e) => {
    const regex = /^[0-9\b]+$/;
    const { name } = e.target;
    const value = e.target.value;
    if (value === "" || regex.test(value)) {
      setDetailsData({
        ...detailsData,
        [name]: value,
      });
    }
  };

  // function to handle multi select on change
  const handleMultiSelectChange = (selectedValues, event) => {
    const name = event.name;

    if (name === "shift") {
      setSelectedShift(selectedValues);
    } else if (name === "subject") {
      setSelectedSubChoices(selectedValues);
    } else if (name === "general_tuition_classes") {
      setSelectedGeneralClasses(selectedValues);
    } else if (name === "home_tuition_classes") {
      setSelectedHomeClasses(selectedValues);
    } else if (name === "board") {
      setSelectedBoardChoices(selectedValues);
    } else {
      return;
    }

    let arr = selectedValues.map((element) => {
      return element.value;
    });

    setDetailsData({ ...detailsData, [name]: arr.join(", ") });
  };

  // function to control only number input for fee
  const handleOnlyNumFee = (index, event) => {
    const regex = /^[0-9\b]+$/;
    const value = event.target.value;
    let data = [...feeData];

    if (value === "" || regex.test(value)) {
      data[index][event.target.name] = value;
      setFeeData(data);
    }
  };

  // function to handle onchange fee states
  const handleFeeChange = (index, event) => {
    let data = [...feeData];
    data[index][event.target.name] = event.target.value;
    setFeeData(data);
  };

  // function to handle change in the class of fee
  const handleClassForFee = (selectedValues, index, event) => {
    let selection = [...selectedClass];
    selection[index] = selectedValues;
    setSelectedClass(selection);
    let newArr = [];
    selectedValues.map((element) => {
      newArr.push(element.value);
    });
    let data = [...feeData];
    data[index][event.name] = newArr.join(", ");

    setFeeData(data);
  };

  // handling submission of data
  const profileDataSubmit = async (e) => {
    e.preventDefault();
    setProgress(20);

    let newDetailsData = {};

    Object.keys(detailsData).forEach((key) => {
      if (detailsData[key] !== profile[key]) {
        newDetailsData[key] = detailsData[key];
      }
    });

    if (photo !== profile.profile_photo) {
      newDetailsData.profile_photo = photo;
    }
    try {
      let response = await dispatch(
        updateTeacherProfile({ newDetailsData, feeData, rmvFeeDataIds })
      ).unwrap();
      setProgress(60);

      NotificationBar(`${response.message}`, "success", 5000);
      setProgress(100);
      navigate("/teacher/profile");
    } catch (error) {
      if (error.response) {
        NotificationBar(
          "We are facing some problems. Please try submitting your details after some time.",
          "error"
        );
      } else {
        NotificationBar(
          "We are facing server problems. Please try submitting your details after some time.",
          "error"
        );
      }
    }
  };

  const nextPage = () => {
    setPage((currPage) => currPage + 1);
  };
  // ONCLICK FOR PREVIOUS BUTTON
  const prevPage = () => {
    setPage((currPage) => currPage - 1);
  };

  const formPage = () => {
    if (page === 0) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <PersonalDetailsForm
            detailsData={detailsData}
            changePhoto={changePhoto}
            photoPreview={photoPreview}
            handleChangeFormData={handleChangeFormData}
            handleOnlyNumbers={handleOnlyNumbers}
            isPhotoResizing={isPhotoResizing}
            locationOnClick={handleLocationSelectClick}
            locationOnChange={handleLocationOnChange}
            suggestions={searchLocation.data?.data.predictions}
            isSuggestionSelected={isLocationSelected}
          />
        </Suspense>
      );
    } else if (page === 1) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <TuitionForm
            detailsData={detailsData}
            handleChangeFormData={handleChangeFormData}
            handleOnlyNumbers={handleOnlyNumbers}
            handleMultiSelectChange={handleMultiSelectChange}
            subChoices={subChoices}
            selectedSubChoices={selectedSubChoices}
            boardChoices={boardChoices}
            selectedBoardChoices={selectedBoardChoices}
            selectedShift={selectedShift}
            tuitionClassesStates={tuitionClassesStates}
          />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <FeeIndex
            feeData={feeData}
            setFeeData={setFeeData}
            selectedClass={selectedClass}
            handleFeeChange={handleFeeChange}
            handleClassForFee={handleClassForFee}
            setSelectedClass={setSelectedClass}
            handleOnlyNumFee={handleOnlyNumFee}
            setRmvFeeDataIds={setRmvFeeDataIds}
            rmvFeeDataIds={rmvFeeDataIds}
            detailsData={detailsData}
            tuitionClassesStates={tuitionClassesStates}
          />
        </Suspense>
      );
    }
  };

  return (
    <>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />

      <Helmet>
        <title>
          {profile.name ? "Edit Profile - " + profile.name : "Edit Profile"}
        </title>
      </Helmet>

      <Container>
        <Header>
          <p>{heading[page]}</p>
        </Header>
        <DetailsForm onSubmit={profileDataSubmit}>
          <FormBody>
            <>{formPage()}</>
          </FormBody>
          <BtnContainer>
            <PrevBtn type="button" onClick={prevPage}>
              <i className="fas fa-angle-double-left"></i> Previous
            </PrevBtn>
            {page === heading.length - 1 ? (
              <SubmitBtn type="submit">Submit</SubmitBtn>
            ) : (
              <NextBtn type="button" onClick={nextPage}>
                Next <i className="fas fa-angle-double-right"></i>
              </NextBtn>
            )}
          </BtnContainer>
        </DetailsForm>
      </Container>
    </>
  );
};

export default EditDetailsForm;
