import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getStudentPaymentStatus } from "../../../api/teacher";
import StyledTable from "../../../components/Table/StyledTable";
import {
  BoldText,
  PaymentStatusValue,
  ItemCount,
  StudentNameAndPic,
} from "../../../components/Table/tableStyles";
import {
  PaymentFilterButton,
  PaymentStatusFitlerContainer,
  PaymentWrapper,
  TransferButton,
} from "./layoutStyles";

const PaymentStatus = () => {
  const [searchParam, setSearchParam] = useSearchParams();

  const tuitionTypeParam = searchParam.get("tuition_type");
  const paymentStatusParam = searchParam.get("payment_status");
  const monthParam = searchParam.get("month");
  const yearParam = searchParam.get("year");

  const [tableCoulumns, setTableColumns] = useState([]);

  const isParams =
    tuitionTypeParam !== null &&
    paymentStatusParam !== null &&
    monthParam !== null &&
    yearParam !== null;

  const [paymentStatusActiveBtn, setPaymentStatusActiveBtn] = useState(
    paymentStatusParam || null
  );

  const paymentStatusButtons = [
    { id: "PENDING", label: "PENDING" },
    { id: "PAID", label: "PAID" },
  ];

  useEffect(() => {
    if (paymentStatusActiveBtn === null) {
      setPaymentStatusActiveBtn(paymentStatusParam);
    }
  }, [paymentStatusParam, paymentStatusActiveBtn]);

  const { data: studentData } = useQuery({
    queryKey: [
      "teacher/fetchPaymentStatus",
      tuitionTypeParam,
      paymentStatusParam,
      monthParam,
      yearParam,
    ],
    queryFn: () =>
      getStudentPaymentStatus({
        paymentStatusParam,
        tuitionTypeParam,
        monthParam,
        yearParam,
      }),
    refetchOnWindowFocus: false,
    enabled: isParams === true,
  });

  const handleFilterBtnClick = (e) => {
    searchParam.set("payment_status", e.target.id);
    setSearchParam(searchParam);
    setPaymentStatusActiveBtn(e.target.id);
  };

  useEffect(() => {
    console.log(studentData?.data);
    if (studentData) {
      let coloumns = [
        {
          Header: "Student",
          dataKey: "student_name",
          Cell: (item) => (
            <StudentNameAndPic>
              <img src={item.student_profile_photo} alt={item.student_name} />
              {item.student_name}
            </StudentNameAndPic>
          ),
        },
        {
          Header: "Tuition Type",
          dataKey: "tuition_type",
        },
        {
          Header: "Fee Amount",
          dataKey: "fee_amount",
          Cell: (item) => <BoldText>₹ {item.fee_amount}</BoldText>,
        },
        {
          Header: "Payment Status",
          dataKey: "paid_status",
          Cell: (item) => (
            <PaymentStatusValue $status={item.paid_status}>
              {item.paid_status}
            </PaymentStatusValue>
          ),
        },
        {
          Header: "Paid On",
          dataKey: "paid_on",
          Cell: (item) =>
            item.paid_on ? dayjs(item.paid_on).format("DD/MM/YYYY") : "NA",
        },
        {
          Cell: (item) =>
            item.paid_status === "PAID" ? (
              <TransferButton type="button">Trasfer Status</TransferButton>
            ) : null,
        },
      ];

      setTableColumns(coloumns);
    }
  }, [studentData]);
  const generateCaption = (data) => (
    <>
      Fee Information
      {data && (
        <ItemCount>
          {data?.length} {data?.length < 2 ? "Student" : "Students"}
        </ItemCount>
      )}
    </>
  );

  return (
    <PaymentWrapper>
      <PaymentStatusFitlerContainer>
        {paymentStatusButtons.map((btn) => (
          <PaymentFilterButton
            $active={paymentStatusActiveBtn === btn.id}
            key={btn.id}
            id={btn.id}
            onClick={handleFilterBtnClick}
            type="button"
          >
            {btn.label}
          </PaymentFilterButton>
        ))}
      </PaymentStatusFitlerContainer>
      <StyledTable
        data={studentData?.data}
        columns={tableCoulumns}
        caption={generateCaption}
      />
    </PaymentWrapper>
  );
};

export default PaymentStatus;
